import "../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227jOAx9z1cIARZoH1TEaSdtVRTY/1gUC8aibE5kyZDkdDKL/vvCsp3IjpPpoPOUWOL18JDU3zs8KAcVevZv9v3w6EqVs/8WjK3+ij+M2RpyCgfBspcFYx8LxjZX7h7P71bD3dMVvecretnqouLH4m4IO4siudXWCUamREfhJRVYRwFJvtZwaEU0GeRK44/WFmgqDKeAlRcsRxPQtcdb6yQ67kBS4wV7qKNwwB+BRw3BNKrwMuOZsXeSoRQx/PazIsNLpKIMgt2vOkONR8c9asyDYMYajJYa51tTtaUhjBqkJFMIthpCUNYE7uknCrYHd8M5KBXziefVbdSyngJZI5hDDYH20XwFriDDg60Fi2GkGInS7tFFpLaQ7wpnGyMnLqIIj/nejrX/kRCAQ966el0G1+Dy7Wu2JHnYapQjawNAEhU0OkV/ZDtWyWNujQR3ODphA7Ac92iCH4Cfce6gKMgUI+cnft59Gyndx+t522ktYOutbkI8bcnTMz3Wg2c9MY7c6T4H3mTTgj10Me3RKW3fBStJSjRHjp4uUGuqPflovKSA3NeQYxvgu4P6Sgu0HdI33EWUa0cVuMO4hN/GDfeLTnNdhj295+Eaz4Zfl7Fn8ySi740PpA5t36kuLI5Gjr2SoUCgR06zOaeg9cjnJh1DI6AotyYBatTC62ldH6OZngXrCQuG7zNoh8xyawKakMJ7AfXfDfTCrEkif0ojzzYT/m4uRH4hvCsJTSbzup/MDsxQw/hfWVex1d3afzLbNJNuCuRWa6j9ZAYdjQvmbICAN/x5JbGYNTE7xZJBcv8pOj99aTg//1Y3ngEf28QHcOlqexiWUbwsHZld7M3ELZzQmi0LQ/DIyXDbjNc1fB397TjlM0C3whp94HlJWk4fB337J9KdSLo9e871y5l3w7yrxqC0uriIE8t4vaSni4GnyS47joh+JkzaYjOZK6pfUlfjUR30wWFbGR9ck7fSr8sKdtjBtXzrZNoZ2u1JlHHbvC4VaI/LNwEq9GQ9kmi5HDX/Vtt8d3na/+Rk5HH3fJLxw06dK0P35ElQy0HnN/F5yc8Ubsd7t33D/WmIuntbo5lDDQxV0KFyeppnnpFRLT2Rte9XcC+Lj/8BlK2awcULAAA=\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1jy7rhfb';
export var collapsedIcon = '_1jy7rhfa';
export var collapsedIconContainer = '_1jy7rhf8';
export var contentContainer = '_1jy7rhfd';
export var draggedOverEffect = '_1jy7rhff';
export var draggingContainer = '_1jy7rhfe';
export var emojiIcon = '_1jy7rhf7';
export var icon = '_1jy7rhf6';
export var iconsContainer = '_1jy7rhf9';
export var itemContent = '_1jy7rhf4';
export var itemRenameAnchor = '_1jy7rhf3';
export var itemRoot = '_1jy7rhf2';
export var levelIndent = 'var(--_1jy7rhf0)';
export var linkItemRoot = '_1jy7rhf1';
export var postfix = '_1jy7rhf5';