import "../component/src/ui/avatar/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/avatar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWy3LiMBC85yt02SpShVi/8EO55E+2hC3DJEKiJBlCtvLvW5JtsAxxHhsuGFnj7unp0diPz+xYK7plGv0JgzCqlY7Q3zuEgl/ugpCRO4Jwkvx6cEvOakMQDsNubRQVupZqS9CWGgUvMxwsomKOcLAo0jkKFkXiFvFyjoI5Cu7tc293CIVpT9Fj5j2mo1xOUmSxQ00LS5G2FFk2ooijEUXmMSTBFEOROdAotgxh2ioqRgxJPhax9CjiYooizy12krdS3G8+IkiTW0vIg5v7EAa3bqe3u8fLTo79Tu644mD34ll0Xl8j79xprTo5Nd3JcTaiyCYp8taZZWQp4k5s9FEvRyMd4ZSO0IEGkbuE0SebORrpiKZlfKObf17ERTvfwoxBQ9+oq97uFn0bJ47pAJXZEMdsIzYM1htzXu+kBgNSEKQYpwb2zN6Ve6ZqLg8EbaCqmHgY4i6HuHnswebhCJWutOSNcagnyS6m19suviFW7mgJ5kjspl3XwA1TBK14o2Z7qmYY9ymH9/ceC5YK1iAIKpkwTHUXG0IFbGmb+nkcxBoxqhkGgWVjUC3VgapKIxA1CGjFnR7EO06PWBtqGEE72mhWeeVLXfmuh6tGCBBrLz4blrvIvXIX2US5vZHpD8zvjcubFPwAnONyQ8WaEZfl3CY+Pz/6jivRz7qSf9GVwj9ciW9LMmGLamOuHoP/qLDnZD50sn3TxsOX64e+DJRSp/T6nKhA20IRBIKDYLjmzE0ve8V6o0A8ExR4cCsHd9YRENQIzczDuZy+0DbjvrTX9mopDNbwyggqKS9n4xj0G0X3Xrp9npTDWmAwbKvJoCefGm2gPuJSCsOEGW7tmTJQUo7dowRtoaq4q0WjmcKacVYagoQU7JvjtfzM2JarJ1YaXINNzsJ5ENWnIC6S+JECrWj5vFayERUuJZeqt4zWte2QnYItVcd2z5lyLeqwAcPcrmurU+ontw/dnWXgdxf72jtveC4vtE+IfKcsl9rVekVnaTBHaThHqfueXjpdrxhExV46TVcr1ahhIc6j4XSAOxHdDlqES+3AGqUt2k7CxWFmZGN9d1U6IYZeSD0679dmV95+pHhfd3vQsALuEM8dNdQ5HCpdHiPWIYb7b4/W2z+UsmY+bQ4AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var DefaultAvatarBottomItemStyle = '_1012frs7';
export var DefaultAvatarBottomItemWithAnimationStyle = '_1012frs8';
export var DefaultAvatarContainerStyle = '_1012frs4';
export var DefaultAvatarMiddleItemStyle = '_1012frs5';
export var DefaultAvatarMiddleItemWithAnimationStyle = '_1012frs6';
export var DefaultAvatarTopItemStyle = '_1012frs9';
export var avatarFallback = '_1012frsd';
export var avatarImage = '_1012frsc';
export var avatarRoot = '_1012frsa';
export var avatarWrapper = '_1012frsb';
export var blurVar = 'var(--_1012frs1)';
export var hoverWrapper = '_1012frse';
export var removeButton = '_1012frsf';
export var sizeVar = 'var(--_1012frs0)';