import "../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbWvbMBD+nl8hCoUGquK8rlPY/scYYyjS2dYqS0aSm3Sj/31IshPZcZplK+xbfLmX5557fOeH7zMuqtkeMvRrghAXtpb0haBcwn4zQYhpqQ1Bz9TcYUzzXCjADvYO10ZU1Lzg4DD1riWIonQErZZ1CPUpsC2NUE8EZd6yE9yVBM2y7NY/UikKhYWDyhJknQHHSm93hiornNCKoC1lT4XRjeKxEsoe5vYe6Zoy4V7Ckw/Rz2ByqXcElYJzUAF6Y6zHziGnjXQRgJSYlVQVQLocm8nr5OHAAil9qsDFsPSAheB4aD/N8ZVTRzGTgj3RrYRPzjTwLaTsINVaKAemFzYLHh2Jy1UkcasNB4MN5aKxnrpoPplTj0sGMXvaw3n0OBdSAp/Gcn5klHsme/4VqKb9JzhWdI/bcS7WLahKqM42j7akvXlor9bdYA1I6sQzxGSmEApLyB1B+DFm65jo1KIbJ4UCgpRWIaqmnAtVtGGPsV7knhtaFAn3RxRkC7k2EKehlQPlCLq52fSw0a3VsnGhitM1QasIIQgz16Yi8aekDr7c4VV2GziJQFKlL/uttI+pvuNfZ1R9QYG1pAxKLXmiwxPFzGPNNvUBXvIeRAj36ftwkcUgn5TLw7SG9WZn8yVGoYqTGiHtEfbD6m8TjcFctDA7uY6BPr7Q6DMiuTDWYVYKyaOSe+qbrQdyDzGSjoaYiOFxELJInQjK0Oq6d72gdbcgkqzL8b1+Jsfpzs61ctiKnzAQX7CXeNHffavxaiEtFwZYVD3TsqnUCY5Y3VHjNn0mIr29rcOoZHd+NyCM1st6P+3qhOkhtCuFA2xrysLK2Blae3NjwWALEpjrNkkCfx1FN3JM3qTBVtODz65V2DrL3oCRAA3X9FgSpBS1FfZIjgWZJ/cxgfvhLNyzZ9sC04r3D/ebve3tHzL7D308XqfRy4ocJ0AwrdK2B1pPAH18P0Atf1cNJFH+4Bh2p7b3JZUAp/8X+Mj68BYvkVQqw/6SBrZXNfCjsU7kHkF7yUM5UPzCfNn7VYmrDIflux7/8H39DY+5qINlCwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1dim1xeb';
export var dateCell = '_1dim1xea';
export var dndCell = '_1dim1xe2';
export var dragPageItemOverlay = '_1dim1xe1';
export var iconCell = '_1dim1xe8';
export var operationsCell = '_1dim1xec';
export var root = '_1dim1xe0';
export var selectionCell = '_1dim1xe4';
export var tagsCell = '_1dim1xe9';
export var titleCell = '_1dim1xe5';
export var titleCellMain = '_1dim1xe6';
export var titleCellPreview = '_1dim1xe7';
export var titleIconsWrapper = '_1dim1xe3';