import "../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62T0U7CMBSG73mKhiu5OGRMQCjxSYwx3Xo2qlvbtAc2NL67aYcwdEZJvFrSnX7/f875O31a6DYv2oS9jRirlYZGSdpylqaJbTej99H0WDHrKoQrlYYKC+JstgwljEGD2YsiENaCw1IZzZk2IJ0o+4Q0EqyQUukSnCq3xNnqUuU21hRGE3j1ipzthbsBEEWhNEI8b/0kiBYVttA4YYNW+F5gHqQgASIntcf7Mbkdjh8jOjeVcV+w1qlauAPEf5M+Zx7vSOVtJQ48agZtUalSgyKsPWc5akIXjp93nlQROJpQE2feihwhQ2oQdagog91VN7XYTIPdGBZJsvlT42dvi/OoPinzjjLUI2FL4DE3Wg63uuxvh7O5bdks7Zxuj/i07/y39Qx0d4WvU9IyQ2Tq6Kfv9u4/3Z6xq+F997LWJS3Ya09PJUk6pf52B6ydZdY/RjGOQyovsgoHlrS+KtcR9i3cH+OPLX3zAwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var currentGroupType = '_5nxcfx5';
export var headerDisplayButton = '_5nxcfx1';
export var listOption = '_5nxcfx6';
export var menu = '_5nxcfx0';
export var properties = '_5nxcfx7';
export var propertiesWrapper = '_5nxcfx8';
export var propertyButton = '_5nxcfx9';
export var subMenuItem = '_5nxcfx3';
export var subMenuTrigger = '_5nxcfx2';
export var subMenuTriggerContent = '_5nxcfx4';