import "../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbW/bIBD+nl+BKlVqpBI5r0uJtv8xTdNE4GyzYLAAN+mm/ffJYCfYsZu1qrRv8eW4e+65hztmP+Zb6Q5PIkG/JwhxYUtJXwhKJZx2E4SYltoQ9EzNA8Y0TYUC7ODkcGlEQc0L9g7T2jUHkeWOoPWq9EfrENjmRqgDQUltOQrucoLmSXJff1IpMoWFg8ISZJ0Bx/La7gxVVjihFUF7yg6Z0ZXiIRNKZgv7iHRJmXAv/qs+op/BpFIfCcoF56A89MrYGjuHlFbSBQBSYpZTlQFpY+wmfyazMwskr0N5Lvqpeyx4x3P5cYxvnDqKmRTsQPcSPjtTwXcfsoVUaqEcmM6xufdoSVytA4l7bTgYbCgXla2pC+arPnW4ZBCixzWMo8epkBL4NKSrW0Z5zWTHvwBVNf94x4KecNPO5aYBVQjV2hbBFpW38OWVum2sAUmdeIYQzGRCYQmpIwhvQ7SWiVYtunJSKCBIaeVPlZRzobLm2DbkC9xzQ7Ms4v6Cguwh1QZCN7RyoBxBd3e7Dja6t1pWzmdxuiRoHSB4YabaFCT8lNTB1we8Tu49JwFIrPRVt5TmM9Z3+GtE1TcUWErKINeSRzq8Uswi5GxCn+FF9yBAeIzvw00WvXxiLs/d6uebj8aLjEJlVzl82Avs2fq9gYZgLhuYrVyHQF8uNPqCSCqMdZjlQvKg5I765pue3P0ZSQePmIBh2zuyjJ0IWr/tpme0bMdDFHM1PNVHYlxP7FQrh634BT3peXuOl93Jt/6HbCGHo8bt4mqThsLOZGFUsof6/iOMNqvyNG1B+g4hdMyFA2xLyvxYOBpa1ubKgsEWJDDXTosI5CYIa2BhvFqsLaZnn2Ojok2SvALDr8lLHpBSlFbYCyMWZBrT39PHqtfLT6PAR5e0BaYV767pV6vcUwvTYSl8TJVRPdu3afO2EoeJEEyruPxeYRGgp48DdLKjeEYbE92F3gpsF2zn/RQBp/8X+MDYqC21QmKl9OuLCti/qYCflXUirRE0+9unA8Vv9Jd9XBamZVUo7IfuZvi5GyUO47/dNb1HQfv9blwDVwvihD0xbQcfls0L56qIvz+nlA4gDAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_18ltk9ib';
export var dateCell = '_18ltk9ia';
export var dndCell = '_18ltk9i2';
export var dragPageItemOverlay = '_18ltk9i1';
export var iconCell = '_18ltk9i8';
export var operationsCell = '_18ltk9ic';
export var root = '_18ltk9i0';
export var selectionCell = '_18ltk9i4';
export var tagIndicator = '_18ltk9ie';
export var tagIndicatorWrapper = '_18ltk9id';
export var tagsCell = '_18ltk9i9';
export var titleCell = '_18ltk9i5';
export var titleCellMain = '_18ltk9i6';
export var titleCellPreview = '_18ltk9i7';
export var titleIconsWrapper = '_18ltk9i3';