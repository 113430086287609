import "../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71V7W7jIBD8n6dYVTopaUtq56Pt0Yc5YRsnqAQsTHpJTn33k8GuAWOriU73z4bdndlhYJe/0v36snnerOHPDACh7j/B8Fqd3mYAlayZZlJgIFkt+VHTZlXLCkPSfCm22+v2m9NSY/ggat5XShfNTia1loc2rJJMaKoQ/aBC1xiEFPRt9jlbfrHBOKOlVNSwyqXQVGgMd3dNdsHqipMzhozL/H2c4gUxUdAThpUlcEI1uzCxw5BJVVCFMmka/M0KvQ9ZJ4b1ntrmYnu2V9NPRvL3nZJHUWDQioi6IooKbWENVC65VGGd1cKJaFmsrOjtoiIFO9YYtskPZ7XWZ04x1JKzIpSNlJqqf6JaGnYWI29FyAnP56FG8AQrT8S2td4vDvONd+C9uYyapVSHVlhONJ0bOJQst3A/OJnFI0xuL0LYXjCDitKGpROy9Zh5Nr6Z3Le4bV1uHe6A3rPZ/m93tPOgOU3rwClTd/5dB6xfDOvAGBNeerJVo9Z58Q7IKYLSmMhe7qvJ8a+eUW2KhSOTW2ro4O+yIH2KH/gziLsGAh4grU5+gcwkqtibFkBlHlSbcR1Y7hl3Ei2P3rHr4Arn+CfBiqv8EoOio7Pytkd29KoNHl9SlkxQVCl2IOpsp0rAbWpy3jjpxkdnvLvuiRho6b4XI63ER9/nXza3UZmoCAAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottom = '_1h3z464c';
export var edgeLine = '_1h3z464e';
export var horizontal = '_1h3z4647';
export var horizontalIndent = 'var(--_1h3z4641)';
export var indicatorColor = 'var(--_1h3z4642)';
export var left = '_1h3z464d';
export var lineAboveStyles = '_1h3z4644';
export var lineBelowStyles = '_1h3z4645';
export var localLineOffset = 'var(--_1h3z4649)';
export var outlineStyles = '_1h3z4646';
export var right = '_1h3z464b';
export var terminalSize = 'var(--_1h3z4640)';
export var top = '_1h3z464a';
export var treeLine = '_1h3z4643';
export var vertical = '_1h3z4648';