import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VbYvbOBD+vr9iKBQSWAXbTdLWy0HhoH+hHEcpY2scqytLRpJjZ4/+90OSkzhOdtu966ck8/o888wonx7pUBlsyMK3tHscts3wDv65A6iMbsIXAN1iKdwhh+ThDuDHHYDTc1caXT/uPl0XXL9cEMAZVLbSpsnjV4mO/lqw7O0SbImSFsnq43b5k+bPlUmORdLlCxg3oeZ16f+E8BbV12JcHZFtQ6VWW+GEVjlUYiDuo4Sy5EaEBZaPO6M7xVmppTY57NEsGMOqEorYxN1ojjIGhV5PTChOwyxhtMboEIdKNBgRnBcl3SSNhUqbHg23D1PYH34FNhe2lXjIoZI0hC5S7BQTjhqbQ0nKkfHm7511ojqwUitHyk1d/wP/esRfdoUoWUFPgswiWa2ze0juIVltPtxDepnMKiGlr0r5hDbAXlDPgqCBLlPY0LnRez+ZPJ8HackXp5BlmNcNlJvfhXIizsfQjLHj7ySHhwtDOjdk0dAL7urjrE/ZoXtNYle7uS8ia4Rit/1Z8Bd6YFY8CbXLodCGk2GFDgtRaeW8i2YCB3uBlpanqH5ssE7Cbkkfdmyarrbe1iLnoUmWtANk6zb0OC+pIYlO7Ol1J6X3ZCQeWIuKpqcVWNXIdT/LbXXrc0bnGBtYG+SiszmkWYTW4HDiUKIsF2mS7Gtg8C5rh5CoO+eZ5qC0oguRcXaBWFgtOxfIOd3mkI1NTKzvZ/LrFzVpVMT3/SWlara9Fmp7U6j1JhI3O6FYoZ3TzXEek55l6Nlrw1lhCB9zCB/MW8Ki1sIRsy2WlENriPUG2zCvPZlK6j4YLrMm5Xkof/U8Xb1D3sFI8RfernHnWBj5OjnqGugFG3ZOe9vO44kiTJGswsQ/a+3IfBGu/rMWkhtSUd1p8Ru5hvZkLEWBPFYuDJXjsuuejf7nuVmHxl0UjcWmBM7LeqHZcZ9O0ibJ2wsFDmfup8NMYD0jUd2WYs6m1LJrlM/8m6PDuKd/vKlQWnrz9epvSXh5GO1JOXu+nVupMHs157mRwgTw7nWAR+HHDf8XFeNh2ZAJAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mxa';
export var confirmModalContainer = '_1ukx6mxf';
export var confirmModalContent = '_1ukx6mxe';
export var contentHide = '_1ukx6mx5';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mx9';
export var modalContentWrapper = '_1ukx6mx8';
export var modalDescription = '_1ukx6mxc';
export var modalFooter = '_1ukx6mxd';
export var modalHeader = '_1ukx6mxb';
export var modalOverlay = '_1ukx6mx6';
export var promptModalContent = '_1ukx6mxg';
export var widthVar = 'var(--_1ukx6mx0)';