import "../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W226jMBB9z1f4ZaXtgyOgSZo6X+PgAaYFG9km0F3tv69sCDgJuVV9iZTBPp45c+bYy0zuW1hH5O+CkAIwLywjcRTV3W5BCC8xlxQtVIaRFKQF7cI1FwJlzshqW3ck3tQdSSL3s+q3qQPorFQtIwUKAdLFBJq65F+MZCX4RR+NsZh90VRJC9IyYmqeAt2DbaHfsufpZ65VIwUjB65/U8qzDCXQ6QOtNVZcO5RS6Zfd4t9i2VcU+4oyJS01+AfOEHy8oOuX3XFRO9S+jiIX83hnmyx0lhpIlRThkTO1XeEt5zUj256jI9fJ8L8xoKmBElLLiFQSgloSX8tcSpgqOZcIytKXeSufEf71Kryv+CrFK7/v0eLv93uUVVx3R5oezupOrzvj1+yVFqCp5gIb44Q+ntJUkvr+DBruV/a5GFWiOJNgDzSd/h2xElLxjrYobMHIa3RoXchXN00QlCXWBo371BZogXrenEZazesTKSV96r714ywvN29Q+SIbbRyXtcIzAax9I4c8zvS5DekYiVtHvzzlJXTUFBrlJyNRgLjxiHM+8Gx5I+Tbo0MQ6CD2jIwQ229AJNEJxPu85j0RAjWkFpVkg6B2E6tx1DM2anx7Ass97PgxCnYm75trrjpu389nFfqNz9BRGipnPNBZeDzIp0I5qmeV3LgIxuPT3mvdCbl2Ccan4+zuhqiXu85RMkKn0HHOessjRDXW6XcK3BxrU039yniFpfe+AjTawD2CSDCNw0eruTQ11yDtHZZTxuqSp1CoUoC+qqVgzaVnimf14xxi7MbxXp6S5I1VATyczB3twsmrlcEeXUPJLR7gHD854k/CGZGzxxLXqn3o4TC8G0KneU3OD3+s+Re+duFWqyfvkqloVjgufemXyjkB8gvnEA5o0IL45h2bLQUavi/hZiaNNGBDLs5kkf+4wRc/b/B4w8TiWz4087a4sKiP+8/B4Ynw1Gvwom//AbBkVwRQCwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowDownSmallIcon = 'fnbwe57';
export var buttonText = 'fnbwe5j';
export var docListHeader = 'fnbwe50';
export var docListHeaderTitle = 'fnbwe51';
export var rightButtonGroup = 'fnbwe5i';
export var searchIcon = 'fnbwe58';
export var searchInput = 'fnbwe5c';
export var tagIcon = 'fnbwe5g';
export var tagIndicator = 'fnbwe55';
export var tagLabel = 'fnbwe56';
export var tagSelectorItem = 'fnbwe5f';
export var tagSelectorItemText = 'fnbwe5h';
export var tagSelectorTagsScrollContainer = 'fnbwe5e';
export var tagSticky = 'fnbwe54';
export var tagsEditorRoot = 'fnbwe59';
export var tagsEditorSelectedTags = 'fnbwe5b';
export var tagsEditorTagsSelector = 'fnbwe5d';
export var tagsMenu = 'fnbwe5a';
export var titleCollectionName = 'fnbwe53';
export var titleIcon = 'fnbwe52';