import "../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71W247bIBB9z1eglVbaSEvkXJsStf9RVVVFYGzTxWABTrKt9t8rg51gx0madtU3ezzMnDlzmPHk+3Sh+DTdJejXCCEubCnpK0GphMNmhBDTUhuCdtQ8YUzTVCjADg4Ol0YU1Lxi7zCuXXMQWe4IWi5Kf7QOgW1uhHohKKkte8FdTtA0SR7rVypFprBwUFiCrDPgWF7bnaHKCie0ImhL2UtmdKV4yISSycw+I11SJtyrf6uP6B2YVOo9QbngHJSHXhlbY+eQ0kq6AEBKzHKqMiBtjM3obTQ5skDyOpTnop+6x4J3PJYfx/jKqaOYScFe6FbCJ2cq+OZDtpBKLZQD0zk29R4tiYtlIHGrDQeDDeWisgStg/WsTR0qGYTgcQmXweNUSAl8HLLVHaO8JrLjX4Cqmi/esaAH3HRzvkoCqEKo1jYLtqi6ma+u1G1fDUjqxA5CMJMJhSWkjiDclNgS0YpFV04KBQQprfypknIuVNYcW4d8gXpuaJZF1J9QkC2k2kBohlYOlCPo4WHTwUa3VsvK+SxOlwQtAwSvy1SbgoRHSR18ecLL5NFzEoDEQl90S2leY3mHTxdEfUOApaQMci15JMMzwcxCzib0EV50DQKE5/g63GTRyyfm8titfr7pxXiRUajsLIcPe4I9Wf5toCGY8wZmK9ch0Kf7jD4jkgpjHWa5kDwouaO+6aond39G0sEjJmBY947MYyeCErS8765ntKxvSy/qYnisX4hxPrJTrRy24if0xOftOZ53R99yOJsPy4UBFlTPtKwKdYYjZHfUuE2XiUBvZ+owKtlTPRsQRqtFeRi3eXz3ENrnwgG2JWV+ZOwNLWtzZcFgCxKYaydJBH8VRDewS67SYIvx0WffKGyVJFdgRED9Mj2lBClFaYU9kWNBptF6jOB+uAj34ta2wLTi3b19tbaD/UNm/6GO9X0ava3IYQIE0youu6f1CNDH9wPU8HdXQyLl95Zhu2o7P1IRcPr/gJ///w0Mj9pSCyQWynl11y/l9q6aflTWibSG1Sx3jwEUv9Fy9n5ZwnTDfh6vhn+F334DRtyk13cLAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_14nd1fvb';
export var dateCell = '_14nd1fva';
export var dndCell = '_14nd1fv2';
export var dragPageItemOverlay = '_14nd1fv1';
export var iconCell = '_14nd1fv8';
export var operationsCell = '_14nd1fvc';
export var root = '_14nd1fv0';
export var selectionCell = '_14nd1fv4';
export var tagsCell = '_14nd1fv9';
export var titleCell = '_14nd1fv5';
export var titleCellMain = '_14nd1fv6';
export var titleCellPreview = '_14nd1fv7';
export var titleIconsWrapper = '_14nd1fv3';