import "../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81W0ZabIBB9z1fw0nPaB/aoddOU/ZgeIqOyQXCBbEx7+u89IrpgTDbmqS8xAsPM3Llzx6dfaSaeM/uWoD8bhNQ76FKoE0E1ZwzkywYhC53FHxsgBG8NN/3WqeYWsGlpAQRJddK0fdn83TyNl6buUsZNK+iZoFJA15u9Ho3l5RkXSlqQliB3A96DPcHgs6ItQbvWnaaCVxJzC40hqABpQffLLWWMy4qgdNt2w8/O/4liyJZj6J+YcQ2F5UoSVChxbJzvE2e2JihNki/9aw28qu3H+yVEgbPv150RlH5qni+bf4pGcMXzo5BPeGZJ26EsH9ztlWagsVUtQWnbIaMEZ+id6q8Y07LkErA/Uiih9LcJ2p4LBA2M8Bmk2aw0WxdrqaTFhv8GglLv1S2dPPB5kvRrovc1FiPLhoPOKYnjcXw1UCjJqD4HcU0Z5hNPHkkwSODHkICrbnabVf4SAaW9H8mRLLgLG3JaPRNEj1ZFIe2u9nEE6rYHNTD76cwmhJKRbisADm6jyyS80smOHGHlPRl8jePCJ8PizTbar3Lv+72h3dgdrl60OFRaHSXDSxgE263mTUy0JfSHDvCkW9TNkSP308MvaMr40UwKEdG8l8R81nbF+uqkyWV58qXyzFucXaXjCkUO9c+dMrXm8kBQzGGIOdzz52M4rHR5r2KX/8GEqwIVSlfmeTO5ejm5RXEZvQfmfO3kjUdtcNOru6mhuuLSS+ggBMGZgzsTDe9gV9zcbdzuUp/PmzswkhfT65ZgPSak6u7AxkjsWQBB3FLBiwvV381Uv407xs3FLJqLkbYElm9DRbjEHtPdvB7anfhERuueS8MOLrkQwOL8TRxg9GlyNKCxAQGF7UVUwkygRuTjcuQPfDh4LPbKWtXc/ZnwD2iTbIVbCwAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = '_12l52tqq';
export var bottomButton = '_12l52tqp';
export var bottomLeft = '_12l52tq4';
export var collectionEditContainer = '_12l52tqi';
export var confirmButton = '_12l52tqj';
export var ellipsis = '_12l52tq0';
export var includeItem = '_12l52tqb';
export var includeItemContent = '_12l52tqa';
export var includeItemContentIs = '_12l52tq9';
export var includeItemTitle = '_12l52tq8';
export var includeListTitle = '_12l52tq6';
export var includeTitle = '_12l52tqc';
export var pageList = '_12l52tql';
export var pagesList = '_12l52tq3';
export var pagesTab = '_12l52tq2';
export var pagesTabContent = '_12l52tq1';
export var previewActive = '_12l52tqr';
export var previewCountTips = '_12l52tqn';
export var previewCountTipsHighlight = '_12l52tqm';
export var resultPages = '_12l52tqk';
export var rulesBottom = '_12l52tq5';
export var rulesContainer = '_12l52tqh';
export var rulesContainerLeft = '_12l52tqg';
export var rulesContainerLeftContent = '_12l52tqe';
export var rulesContainerLeftContentInclude = '_12l52tqd';
export var rulesContainerLeftTab = '_12l52tqf';
export var rulesContainerRight = '_12l52tq7';
export var rulesTitle = '_12l52tqs';
export var rulesTitleHighlight = '_12l52tqo';